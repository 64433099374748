<template>
  <b-row>
    <!-- If Pending (Decline / Accept) -->
    <template v-if="order.orderStatus === 'pending'">
      <b-col>
        <b-button @click="onDeclineOrder" pill block>
          Decline
        </b-button>
      </b-col>
      <b-col>
        <b-button @click="onAcceptOrder" variant="primary" pill block>
          Accept
        </b-button>
      </b-col>
    </template>

    <!-- If Accepted (Cancel / Ship) -->
    <template v-if="order.orderStatus === 'accepted'">
      <b-col>
        <b-button @click="onCancelOrder" pill block>
          Cancel Order
        </b-button>
      </b-col>
      <b-col>
        <b-button @click="onShipOrder" variant="primary" pill block>
          Ship Order
        </b-button>
      </b-col>
    </template>

    <!-- If Shipped (Nor-delivered / Deliered) -->
    <template v-if="order.orderStatus === 'shipped'">
      <b-col>
        <b-button @click="onNotDelivered" pill block>
          Not-Delivered
        </b-button>
      </b-col>
      <b-col>
        <b-button @click="onDelivered" variant="primary" pill block>
          Delivered
        </b-button>
      </b-col>
    </template>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";

import { updateOrderStatus } from "../../apis/merchant";

export default {
  props: ["order"],
  computed: {
    ...mapGetters({
      token: "login/getToken",
    }),
  },
  methods: {
    onDeclineOrder() {
      this.makeUpdateStatusRequest("merchant_declined");
    },
    onAcceptOrder() {
      this.makeUpdateStatusRequest("accepted");
    },
    onCancelOrder() {
      this.makeUpdateStatusRequest("merchant_cancelled");
    },
    onShipOrder() {
      this.makeUpdateStatusRequest("shipped");
    },
    onNotDelivered() {
      this.makeUpdateStatusRequest("merchant_cancelled");
    },
    onDelivered() {
      this.makeUpdateStatusRequest("delivered");
    },
    makeUpdateStatusRequest(newOrderStatus) {
      updateOrderStatus(this.order.id, newOrderStatus, this.token).then(
        (data) => {
          this.$emit("orderStatusSuccess", data);
        }
      );
    },
  },
};
</script>

<style></style>
