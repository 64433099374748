<template>
	<b-card v-if="order">
		<!-- Order Data -->
		<div>
			<b-row align-h="between" class="mx-2">
				<!-- Order -->
				<p class="font-weight-bold">Order #{{ order.id }}</p>

				<!-- Order Status -->
				<p class="text-capitalize">
					{{ order.orderStatus | orderStatusToDisplay }}
				</p>
			</b-row>

			<b-row class="mx-2">
				<!-- Date -->
				<p>{{ createdTimeDate }}</p>
			</b-row>
		</div>

		<hr />

		<!-- Order Prescriptions -->
		<order-prescriptions :order="order" :isMerchant="true" />

		<!-- Items -->
		<div>
			<!-- Item Count -->
			<p class="text-uppercase text-muted mx-2">{{ itemCountText }}</p>

			<!-- ItemCard -->
			<MerchantOrderItem v-for="item in this.order.items" :key="item.id" :orderId="order.id" :orderItem="item" :priceAndQuantityEditable="orderEditable" @confirmItemUpdate="onConfirmItemUpdate" />

			<!-- Add Order Item to Order -->
			<b-button v-if="orderEditable" @click="enableAddItemModal" variant="primary" block>
				Add Item
			</b-button>
		</div>

		<!-- If Pending Status, Edit Order Option -->
		<template v-if="order.orderStatus === 'pending'">
			<hr />
			<div>
				<b-row align-h="between" align-v="center" class="mx-2">
					<!-- Edit Order Text -->
					<span>Edit Order</span>

					<!-- Edit/Confirm change -->
					<b-button v-if="!orderEditable" @click="enableEdit">
						Edit
					</b-button>

					<b-button v-if="orderEditable" @click="closeEdit" variant="primary">
						Close
					</b-button>
				</b-row>
			</div>
		</template>

		<!-- Costing -->
		<hr />
		<div>
			<!-- Items Price -->
			<b-row align-h="between" class="mx-2">
				<!-- Item Total Text -->
				<p>Item Total</p>

				<!-- Items Price -->
				<p>{{ order.totalItemsPrice.toString() | rupeeCurrency }}</p>
			</b-row>

			<!-- Delivery -->
			<b-row align-h="between" class="mx-2">
				<!-- Delivery Text -->
				<p>Delivery</p>

				<!-- Delivery -->
				<p>{{ order.deliveryCost.toString() | rupeeCurrency }}</p>
			</b-row>

			<!-- Grand Total -->
			<b-row align-h="between" class="mx-2">
				<!-- Grand Total Text -->
				<h5 class="font-weight-bold">Grand Total</h5>

				<!-- Total Amount -->
				<h5 class="font-weight-bold">
					<template v-if="hasItemWithNoPrice"> {{ grandTotal | rupeeCurrency }} + {{ "**" | rupeeCurrency }} </template>
					<template v-else>
						{{ grandTotal | rupeeCurrency }}
					</template>
				</h5>
			</b-row>

			<b-row v-if="hasItemWithNoPrice">
				<b-col>
					<MarketPrice class="mt-3" />
				</b-col>
			</b-row>
		</div>

		<!-- CUSTOMER DETAILS -->
		<hr />
		<order-customer-detail v-if="order && order.customerDetail" :customerDetail="order.customerDetail" />

		<!-- Accept or Decline (If not completed) -->
		<b-card-footer v-if="displayFooter" class="mt-5">
			<OrderStatusButtons v-if="order" :order="order" @orderStatusSuccess="onOrderStatusSuccess" />
		</b-card-footer>

		<!-- Modal to add Product to Order Item -->
		<b-modal v-model="showAddOrderItemModal" size="lg" hide-header hide-footer>
			<template v-if="orderEditable && order">
				<!-- List -->
				<OrderProductsList v-if="!selectedProduct" :order="order" @itemSelected="itemSelected" />

				<!-- Selected Product Item Form -->
				<OrderSelectedProductForm v-if="selectedProduct" :order="order" :product="selectedProduct" @success="successAddItem" @cancel="cancelAddItem" />
			</template>
		</b-modal>
	</b-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import dateformat from "dateformat";

import MerchantOrderItem from "../../../components/orders/OrderItem";
import MarketPrice from "../../../components/common/MarketPrice";
import OrderCustomerDetail from "../../../components/orders/OrderCustomerDetail";
import OrderStatusButtons from "../../../components/orders/OrderStatusButtons";

import OrderProductsList from "../../../components/orders/OrderProductsList";
import OrderSelectedProductForm from "../../../components/orders/OrderSelectedProductForm.vue";
import OrderPrescriptions from "../../../components/prescriptions/OrderPrescriptions.vue";

import { getOrderItems, getOrder as getOrderApi } from "../../../apis/merchant";

export default {
	components: {
		MerchantOrderItem,
		MarketPrice,
		OrderCustomerDetail,
		OrderStatusButtons,
		OrderProductsList,
		OrderSelectedProductForm,
		OrderPrescriptions,
	},
	async created() {
		let order = null;
		if (this.orderId) {
			order = await this.getOrder();
		}
		if (!order) {
			this.$router.push({ name: "Orders" });
			return;
		}
		// set Order details
		this.order = {
			...order,
			items: [], // setting items as empty, since they arent passed in order
		};
		getOrderItems(this.order.id, this.token).then((data) => {
			// Data is List of items
			this.order.items = [...data];
		});
	},
	data() {
		return {
			orderId: this.$route.params.orderId ? this.$route.params.orderId : this.$route.query.orderId,
			order: null,
			// order: {
			// 	id: 0,
			// 	orderStatus: "",
			// 	totalItemsPrice: 0,
			// 	deliveryCost: 0,
			// 	orderAmount: 0,
			// 	createdTime: 0,
			// 	hasItemWithNoPrice: false,
			// 	customerDetail: null,
			// 	items: [],
			// },

			orderEditable: false,
			showAddOrderItemModal: false,
			selectedProduct: null,
		};
	},
	computed: {
		...mapGetters({
			// getOrder: "merchant/orders/getItem",
			token: "login/getToken",
		}),
		createdTimeDate() {
			const date = new Date(this.order.createdTime);
			return dateformat(date, process.env.VUE_APP_DATE_TIME_FORMAT);
		},
		itemCountText() {
			const itemCount = this.order.items.length;
			if (itemCount) {
				if (itemCount == 1) {
					return "1 item";
				}
				return `${itemCount} items`;
			}
			return "No items";
		},
		grandTotal() {
			return this.order.orderAmount.toString();
		},
		hasItemWithNoPrice() {
			return this.order.hasItemWithNoPrice;
		},
		displayFooter() {
			const orderStatus = this.order.orderStatus;
			if (orderStatus === "pending" || orderStatus === "accepted" || orderStatus === "shipped") {
				return true;
			}
			return false;
		},
	},
	methods: {
		...mapActions({
			updateOrder: "merchant/orders/addItem",
		}),
		async getOrder() {
			return await getOrderApi(this.orderId, this.token).catch(() => null);
		},

		// Updating Status
		onOrderStatusSuccess(data) {
			// update order with new data
			this.updateOrder(data);
			this.$router.go(-1);
		},

		// Updating Order Item
		enableEdit() {
			this.orderEditable = true;
		},
		closeEdit() {
			this.orderEditable = false;
		},
		onConfirmItemUpdate(orderItem) {
			// update order item in order
			this.putOrderItemInOrder(orderItem);
		},

		// Add Order Item Using Product
		enableAddItemModal() {
			this.selectedProduct = null; // resetting any product selected
			this.showAddOrderItemModal = true;
		},
		// Item Selected
		itemSelected(product) {
			this.selectedProduct = product;
		},
		successAddItem(orderItem) {
			// put item in order
			this.putOrderItemInOrder(orderItem);
			// close modal
			this.showAddOrderItemModal = false;
			// remove selected item
			this.selectedProduct = null;
		},
		// On cancel of selected product form
		cancelAddItem() {
			this.selectedProduct = null;
		},

		// Common method to add order item to order & update order
		async putOrderItemInOrder(orderItem) {
			// add/put item to item
			const items = this.putItemInList(this.order.items, orderItem, "id");
			// get updated order data
			const updatedOrder = await this.getOrder();
			// set new order
			this.order = { ...updatedOrder, items };
		},
	},
};
</script>
