<template>
  <product-card :imageSrc="imageSrc">
    <!-- Title -->
    <div class="text-center">
      <span class="store-product-title m-0 p-0">
        {{ title }}
      </span>
    </div>

    <!-- Price -->
    <p class="store-price-unit small m-0 p-0 mb-1">
      <template v-if="price">
        {{ price.toString() | rupeeCurrency }} - {{ unit }}
      </template>
      <template v-else> {{ "**" | rupeeCurrency }} - {{ unit }} </template>
    </p>

    <!-- Button to Select Product -->
    <b-button @click="selectItem" size="sm" class="mt-2">Select</b-button>
  </product-card>
</template>

<script>
import { mapGetters } from "vuex";
import { getProductImages } from "../../apis/merchant";

import ProductCard from "../common/ProductCard.vue";

export default {
  components: { ProductCard },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    getProductImages(this.product.id, this.token).then((data) => {
      // array of productImages setting to state
      const images = data.map((image) => {
        const src = image.productImage.blobUrl;
        return { id: image.id, src };
      });
      this.images = [...images];
    });
  },
  data() {
    return {
      title: this.product.name,
      price: this.product.price,
      unit: this.product.unit,

      images: [],
    };
  },
  computed: {
    ...mapGetters({
      token: "login/getToken",
    }),
    imageSrc() {
      const productImages = this.images;
      if (productImages.length > 0) {
        const src = productImages[0].src;
        return src;
      }
      return null;
    },
  },
  methods: {
    selectItem() {
      this.$emit("itemSelected", this.product);
    },
  },
};
</script>
