<template>
  <b-card header-tag="header" header-text-variant="primary" body-class="px-2">
    <!-- HEADER -->
    <template #header>
      <b-card-title>
        Products
      </b-card-title>

      <!-- Search -->
      <b-form-input
        type="search"
        id="search"
        v-model="search"
        @update="onFilterChange"
        placeholder="Search"
        class="mt-4"
        trim
      />
    </template>

    <!-- Pagination -->
    <div>
      <b-pagination
        v-model="currentPage"
        :total-rows="count"
        :per-page="perPageCount"
        @change="getPaginatedProducts"
        align="center"
      ></b-pagination>
    </div>

    <b-row>
      <b-col
        cols="6"
        sm="6"
        md="4"
        lg="3"
        v-for="product in products"
        :key="product.id"
      >
        <OrderProductItem
          :product="product"
          @itemSelected="itemSelected"
          class="mb-3"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import { getProducts, getProductsCount } from "../../apis/merchant";

import OrderProductItem from "./OrderProductItem.vue";

export default {
  components: { OrderProductItem },
  props: ["order"],
  created() {
    this.initProducts();
  },
  data() {
    return {
      products: [],

      search: "",

      // for pagination
      currentPage: 1,
      perPageCount: 6,
      count: 0,
    };
  },
  computed: {
    ...mapGetters({
      token: "login/getToken",
    }),
    apiParams() {
      return {
        name: this.search,
        page: this.currentPage - 1,
        size: this.perPageCount,
        sort: "id,desc", // for sorting by id desc
      };
    },
  },
  methods: {
    initProducts() {
      const params = this.apiParams;
      getProductsCount(this.token, params).then((data) => {
        this.count = data.count;
        this.getPaginatedProducts(this.currentPage);
      });
    },
    getPaginatedProducts(page) {
      // first set the page
      this.currentPage = page;

      const params = this.apiParams;
      getProducts(this.token, params).then((data) => {
        this.products = [...data];
      });
    },
    onFilterChange(newValue) {
      this.search = newValue;
      this.initProducts();
    },
    itemSelected(product) {
      this.$emit("itemSelected", product);
    },
  },
};
</script>

<style></style>
