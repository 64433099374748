<template>
  <div>
    <h4 class=" text-center mb-4">{{ product.name }}</h4>

    <!-- Form Fields -->
    <b-row>
      <b-col>
        <!-- Quantity Spinner -->
        <b-form-group
          label="Quantity*"
          label-for="quantity"
          :invalid-feedback="getErrorMessage(errors, 'quantity')"
          :state="getErrorState(errors, 'quantity')"
        >
          <b-form-spinbutton
            :state="getErrorState(errors, 'quantity')"
            id="quantity"
            v-model="form.quantity"
            min="0"
            :step="quantitySpinData(unit).increment"
            :max="quantitySpinData(unit).max"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <!-- Price -->
        <b-form-group
          label="Price*"
          label-for="productPrice"
          :invalid-feedback="getErrorMessage(errors, 'productPrice')"
          :state="getErrorState(errors, 'productPrice')"
        >
          <b-form-input
            :state="getErrorState(errors, 'productPrice')"
            id="productPrice"
            v-model="form.productPrice"
            placeholder="Price"
            @keypress="isNumber($event)"
            trim
          />
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Buttons -->
    <div class="text-center">
      <b-button @click="addProductToOrder" variant="primary" class="mr-3">
        Add
      </b-button>
      <b-button @click="cancel">
        Cancel
      </b-button>
    </div>
  </div>
</template>

<script>
import { quantitySpinData } from "../../app-functions";
import { mapGetters } from "vuex";
import { addOrderItem } from "../../apis/merchant";

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.form.productPrice = this.product.price;
  },
  data() {
    return {
      form: {
        quantity: 0,
        productPrice: null,
      },
      errors: null,
    };
  },
  computed: {
    ...mapGetters({
      token: "login/getToken",
    }),
    unit() {
      return this.product.unit;
    },
  },
  methods: {
    quantitySpinData,

    addProductToOrder() {
      addOrderItem(this.order.id, this.product.id, this.form, this.token)
        .then((data) => {
          this.$emit("success", data);
        })
        .catch(({ data }) => {
          this.errors = data.errors;
        });
    },
    cancel() {
      this.$emit("cancel");
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style></style>
